    <template>  
      <div class="home-container">  
        <div class="header">
          <div class="welcome-text">Hクラブ</div>
        </div>  
        <div class="content">
          <div class="banner">  
            <swiper class="banner_swiper" :options="bannerSwiperOption">  
              <swiper-slide v-for="(v, key) in banners" :key="key">  
                <van-image class="banner_img" round :src="v.url">  
                  <template v-slot:loading>  
                    <van-loading type="circular" />  
                  </template>  
                </van-image>  
              </swiper-slide>  
            </swiper>  
          </div>  
          <div class="notice-bar">  
            <van-notice-bar  
              class="notice-swipe"  
              left-icon="bullhorn-o"  
              background="#ffffff"  
              color="#7e5678"  
              :text="this.notice"  
            />  
            <div class="linear-gradient"></div>  
          </div> 
        <div class="premium-section">
          <div class="premium-text">
             VIP 
            <span class="champagne-text">究極の贅沢体験をお楽しむ</span>
          </div>
        </div>
    <div class="image-grid">
        <div v-for="(info, index) in infos" :key="index" class="image-item">
          <div class="image-wrapper">
            <img :src="info.imgUrl" :alt="info.name" class="grid-image">
            <span class="watermark">高級な</span>
          </div>
        </div>
      </div>
      <div class="personal-info-container">
        <div v-for="(info, index) in infos" :key="index" class="personal-info-item">
          <div class="image-container">
            <img :src="info.imgUrl" :alt="info.name" class="personal-image">
          </div>
          <div class="text-container">
            <div class="top-info">
              <img src="img/vip.png" class="vip-icon">
              <div class="certification-info"> <span>{{ info.certification }}</span></div>
              <div class="video-certification-info">ビデオ認証</div>
              <div class="name-info"><span>{{ info.name }}</span></div>
            </div>
            <div class="service-quality">
              サービス品質: <span class="stars">★★★★★</span>
            </div>
            <div class="metrics">
            <div class="height-info">
              身長: <span>{{ info.height }} cm</span>
            </div>
            <div class="bust-info">
              胸囲: <span>{{ info.bust }} </span> <!-- 确保infos对象中有bust属性 -->
            </div>
            </div>
            <div class="more-info">
              オーバーヘッド: <span>{{ info.moreInfo }}</span>
            </div>
            <div class="additional-info">
              <span class="info-item" style="background-color: ;"> {{ info.profession }}</span>
              <span class="info-item" style="background-color: ;"> {{ info.personality }}</span>
              <span class="info-item" style="background-color: ;"> {{ info.area }}</span>
            </div>
          </div>
        </div>
      </div>
        </div>  
      </div>  
    </template>  
      
    <script>  
    export default {  
      data() {  
        return {  
          notice: "获取中......",  
          banners: [{}],  
          infos: [  
            { imgUrl: '/img/sy/1.jpg', name: '美咲(Misaki)', age: 28, area: '東京',moreInfo: '正しい',profession: '妻',height: '173',bust: 'c+', personality: '明るい' },  
            { imgUrl: '/img/sy/2.jpg', name: '芳恵(Yoshie)', age: 24, area: '京都',moreInfo: '正しい',profession: '学区生', height: '181',bust: 'B+', personality: '内向' },  
            { imgUrl: '/img/sy/3.jpg', name: '由美(Yumi)', age: 30, area: '大阪',moreInfo: '正しい',profession: 'ホワイトカラー', height: '167',bust: 'C', personality: '活発である' },  
            { imgUrl: '/img/sy/4.jpg', name: '直子(Naoko)', age: 26, area: '名古屋',moreInfo: '正しい',profession: '秘書', height: '158',bust: 'C+', personality: 'しっかりしている' },  
            { imgUrl: '/img/sy/5.jpg', name: '美和(Miwa)', age: 29, area: '広島',moreInfo: '正しい',profession: '学区生', height: '160',bust: 'B+', personality: '情熱' },  
            { imgUrl: '/img/sy/6.jpg', name: '涼子(Ryoko)', age: 27, area: '横浜',moreInfo: '正しい',profession: '看護師',height: '155',bust: 'D',  personality: '落ち着いて' }  
          ],  
          bannerSwiperOption: {  
            effect: 'coverflow',  
            grabCursor: true,  
            centeredSlides: true,  
            slidesPerView: 'auto',  
            speed: 800,  
            autoplay: true,  
            coverflowEffect: {  
              rotate: 50,  
              stretch: 10,  
              depth: 100,  
              modifier: 1,  
              slideShadows: true  
            }  
          }  
        };  
      },  
      methods: {  
        getBasicConfig() {  
          this.$http({  
            method: 'get',  
            url: 'sys_config'  
          }).then(res => {  
            this.getNotice(res.data);  
            this.getBanner(res.data);  
            // 假设你也可以从res.data中获取infos数据  
            // this.infos = res.data.infos;  
          });  
        },  
        getNotice(data) {  
          this.notice = data.notice;  
        },  
        getBanner(data) {  
          this.banners = data.banners;  
        }  
      },  
      mounted() {},  
      created() {  
        this.getBasicConfig();  
      }  
    };  
    </script>  
      
    <style lang='less' scoped>  
    @notice-bar-size: 30px;  
      
    .home-container {  
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #ffffff;  
    }

    .header {
      width: 100%;
      height: 100px;
      background: linear-gradient(270deg, #d63f8c, #7d76ef);
      position: relative;
      top: 0;
      left: 0;
      right: 0;
    }

    .welcome-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 7.2vw;
      font-weight: bold;
    }

    .banner {  
      width: 100vw;
      margin-top: 5px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
    }

    .banner_swiper {  
      width: 100%;
      
      .swiper-slide {  
        width: 100vw !important;
        height: 300px;
        border-radius: 0;
        display: flex;  
        justify-content: center;  
        align-items: center;
      }  
    }

    .banner_img {  
      width: 100vw !important;
      height: 100%;
      border-radius: 0 !important;
    }

    ::v-deep .swiper-container-3d .swiper-slide-shadow-left,
    ::v-deep .swiper-container-3d .swiper-slide-shadow-right {
      display: none;
    }
      
    .linear-gradient {  
      width: 100%;  
      height: 2px;  
      background: linear-gradient(  
        to right,  
        rgba(126, 86, 120, 0),  
        rgb(230, 195, 161),  
        rgba(126, 86, 120, 0)  
      );  
    }  
      
    ::v-deep .van-notice-bar__left-icon,  
    .van-notice-bar__right-icon {  
      min-width: 40px;  
    }  
      
    .notice-swipe {  
      width: calc(100% - 50px);  
      height: 85px;  
      font-size: @notice-bar-size;  
    }  
      
    ::v-deep .van-icon-bullhorn-o::before {  
      transform: scale(2.5);  
    }  
    .image-grid {
      padding: 20px; // 调整内边距以控制网格与容器边缘的距离
      display: grid;
      grid-template-columns: repeat(3, 1fr); // 3列布局，每列宽度相等
      gap: 10px; // 网格之间的间距
      margin-top: 20px; // 与公告栏的垂直间距
    }
     
    .image-item {
      // 可以根据需要添加额外的样式
    }
     
    .image-wrapper {
      width: 100%; // 使容器宽度自适应网格单元
      height: 190px; // 固定高度或根据需要调整
      position: relative;
      overflow: hidden; // 防止图片溢出容器
    }
     
    .grid-image {
      width: 100%; // 图片宽度自适应容器
      height: 100%; // 图片高度自适应容器
      object-fit: cover; // 保持图片的纵横比，同时填充整个容器
      border-radius: 10px; // 圆角效果，根据需要调整
    }

    .watermark {
      position: absolute;
      top: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      padding: 2px 8px;
      font-size: 12px;
      transform: rotate(45deg) translate(15px, -10px);
      transform-origin: 100% 0;
      border-radius: 0 8px 0 8px;
    }
    .watermark {
        position: absolute;
        top: 11.333vw; /* 调整水印与图片顶部的距离 */
        right: -7.333vw; /* 调整水印与图片右侧的距离，负值使其部分超出图片 */
        background: #ebcaaf; /* 设置水印背景颜色为金色 */
        color: #8d684b; /* 设置水印文字颜色为白色 */
        padding: 1.267vw 10.333vw; /* 设置水印的内边距，使其大小适中 */
        font-size: 2.967vw; /* 设置水印字体大小 */
        text-align: center; /* 使水印文字居中 */
        transform: rotate(45deg); /* 旋转水印45度 */
        border-radius: 0 0 0 10px; /* 设置水印的圆角，只圆角右下角 */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* 可选：添加阴影效果使水印更立体 */
      }
      .premium-section {
        width: 100%;
        text-align: left; /* 保持左对齐 */
        margin-top: 20px; /* 根据需要调整间距 */
        margin-bottom: 10px; /* 根据需要调整间距 */
        padding-left: 20px; /* 添加左边距 */
        background-color: #ffffff; /* 添加黑色背景 */
      }
      
      .premium-text {
        display: inline-block;
        color: #000000; /* 字体颜色设置为白色 */
        font-size: 5.4vw;
        font-weight: bold;
      }
      
      .champagne-text {
        color: #bd09f5; /* 字体颜色设置为香槟色 */
      }
      .personal-info-container {
        padding: 20px;
        margin-top: 20px;
        background-color: #f8f8f8;
        border-radius: 10px;
      }
       
      .personal-info-item {
        display: flex;
        align-items: flex-start; // 修改这里以使内容对齐到顶部
        margin-bottom: 15px;
      }
       
      .image-container {
        flex-shrink: 0;
        margin-right: 20px;
      }
       
      .personal-image {
        width: 35vw;
        height: 35vw;
        object-fit: cover;
        border-radius: 0%; /* 圆形图片效果 */
      }
       
      .text-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
      }
       
      .certification-info,
      .video-certification-info,
      .name-info,
      .service-quality,
      .height-info,
      .more-info {
        margin-right: 20px; /* 每个信息项之间的间距 */
      }
       
      // 星星样式的颜色
      .stars {
        color: gold;
      }
      .text-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start; /* 改为左对齐 */
      }
      .metrics {
        display: flex;
        flex-direction: column; // 改为垂直排列
        align-items: flex-start; // 保持左对齐，或者根据需要调整
        margin-bottom: 10px; // 可根据需要调整间距
      }
      .height-info,
      .bust-info {
        margin-bottom: 5px; // 添加这个属性来增加每个信息项之间的间距
      }
       .bust-info {
         margin-bottom: 5px; /* 添加这个属性来增加间距 */
       }
      .top-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
      }
       
      .certification-info,
      .video-certification-info,
      .name-info {
        margin-right: 10px;
      }
       
      .name-info {
        flex-grow: 1; /* 使姓名占据剩余空间 */
        text-align: right; /* 姓名右对齐 */
      }
       
      /* 覆盖之前的 margin 设置 */
      .certification-info,
      .video-certification-info,
      .name-info,
      .service-quality,
      .height-info,
      .more-info {
        margin-right: 0;
      }
      .vip-icon {
        width: 27px;
        height: 27px;
        margin-right: 1px; // 可根据需要调整图片与文本之间的间距
      }
      
      .certification-info,
      .video-certification-info {
        background-color: #e7caaf;
        color: #a4826b;
        padding: 0.67vw 0.07vw; // 添加内边距使背景色覆盖文本周围
        border-radius: 3px; // 可选：添加圆角效果
      }
      
      .top-info {
        display: flex;
        align-items: center;
        // justify-content: space-between; 保持或根据需要调整
        margin-bottom: 5px; // 减少间距以整体上移文本
      }
      
      .name-info {
        flex-grow: 1;
        text-align: right;
        margin-right: 7.333vw; // 保持与其他信息的间距一致
      }
      .additional-info {
        display: flex;
        align-items: center;
        margin-top: 10px; // 根据需要调整与上方内容的间距
      }
       
      .info-item {
        padding: 2px 10px; // 内边距，根据需要调整
        margin-right: 10px; // 每个项目之间的间距
        border-radius: 5px; // 圆角效果
        color: #ffffff; // 文本颜色，确保在深色背景上可读
        font-size: 3.299vw; // 字体大小，根据需要调整
       
        &:nth-child(1) {
          background-color: #1989fa; // 职业背景色
        }
        &:nth-child(2) {
          background-color: #07c160; // 国家背景色
        }
        &:nth-child(3) {
          background-color: #ee0a24; // 性格背景色
        }
      }
       
      .info-item:last-child {
        margin-right: 0; // 移除最后一个项目的右边距
      }
      
      // 确保其他样式不会冲突，可以根据需要微调
    </style>